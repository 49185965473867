import { button } from '../../btnui';
import spaceRocket from '../../../assets/icons/spaceRocket.png';
import android from "../../../assets/images/playstore.png"
import apple from "../../../assets/images/apple.svg"
import { useEffect, useState } from 'react';
import appImage from "../../../assets/images/Group_7061.png"

export default function CTAUI() {

   const getOS = () => {
      const userAgent = window.navigator.userAgent;
      const platform = window.navigator?.platform || '';

      if (/Mac/i.test(platform)) {
         return 'MacOS';
      }
      if (/Win/i.test(platform)) {
         return 'Windows';
      }
      if (/Linux/i.test(platform)) {
         return 'Linux';
      }
      if (/iPhone|iPad|iPod/i.test(userAgent)) {
         return 'iOS';
      }
      if (/Android/i.test(userAgent)) {
         return 'Android';
      }
      return 'Unknown';
   };

   const [os, setOS] = useState('');

   useEffect(() => {
      setOS(getOS());
   }, []);


   return (
      <div
         className="bg-primaryLight  rounded-xl flex h-[26rem] lg:h-[32rem] mt-12 md:mt-20  w-full relative overflow-hidden shadow-[16px_24px_50px_0px_rgba(35,0,39,0.24)]">
         <div className='flex flex-row'>
            <img
               src={appImage}
               className="h-[60.5rem] rounded-2xl mt-28"
            />
            <div
               className="flex flex-col justify-center items-start w-full gap-5 z-10">

               <h1
                  className="font-extrabold text-white max:w-[75rem] text-[2rem] sm:text-2xl md:text-6xl tracking-tight md:tracking-[0.019rem] leading-tight md:leading-[4.15rem] text-left"
               >
                  Ready to Grow with <span className='text-orange-500 font-extrabold'>Kinra?</span>
               </h1>
               <h2
                  title="Seller"
                  className="font-Kumbh400 text-left  text-[#F5F5F5]  text-sm sm:text-lg  md:leading-[1.6rem] pr-0 md:pr-12 "
               >
                  Join a community of successful vendors who are already scaling their businesses with Kinra. Don’t miss out on the chance to take your business to the next level.
               </h2>
               <div
                  className="flex justify-center items-center space-x-2 z-20"
               >
                  <button
                     onClick={() => {
                        if (os == "Android" || os == "Windows") { window.open("https://play.google.com/store/apps/details?id=com.jekinraaapp", "_blank"); return }
                        if (os == "iOS" || os == "MacOS") {
                           window.open("https://apps.apple.com/app/jekinraa-the-shopping-app/id6478771670", "_blank"); return
                        }
                     }}
                     className='px-5 py-4 bg-white rounded-full flex flex-row space-x-3 cursor-pointer'>
                     <div className='flex flex-row items-center space-x-2'>
                        <img src={android} className="h-4 w-7 pr-2 border-r" alt="google" style={{
                           fill: "black",
                           color: "black",
                           filter: "brightness(1) invert(0)"
                        }} />
                        <img src={apple} className="h-4 w-4" alt="google" style={{
                           fill: "black",
                           color: "black",
                           filter: "brightness(1) invert(0)"
                        }} />
                     </div>
                     <div className='text-primaryLight font-semibold text-sm'>Download App</div>
                  </button>
               </div>
            </div>
         </div>
         <div className='hero-container absolute w-full h-full inset-0'>
            <div className="absolute inset-0 ">
               <div className="box box1"></div>
               <div className="box box2"></div>
               <div className="box box3"></div>
               <div className="box box4"></div>
               <div className="box box5"></div>
            </div>
         </div>
      </div >
   )
}